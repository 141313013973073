<template>
  <div class="preview_box">
    <div class="preview_title">{{allDataList.questionnaire.title}}</div>
    <div v-for="(item) in allDataList.dir" :key="item.id">
      <div class="topic_box">
        <h2>{{ item.title }}</h2>
        <div class="topic-list-content" v-for="(items, index) in item.list" :key="items.id">
          <div class="name-content flex">
            <span class="type-text" :style="[getTitleColor(items.type)]">
              {{items.type === 0 ? '单项选择' : items.type === 1 ? '多项选择' : items.type === 2 ? '单项填空' : items.type === 3 ? '多项填空' : items.type === 4 ? '单选填空' : items.type === 5 ? '详细地址' : items.type === 6 ? '日期选择' : ''}}
            </span>
            <span class="topic-title">{{index + 1}}. {{items.title}}{{items.require === 1 ? '（必填）' : ''}}</span>
          </div>
          <div class="" v-if="items.type===0"><!--单项选择-->
            <el-radio-group v-model="radio[index]">
              <el-radio :label="option.title" v-for="option in items.options" :key="option.id">{{ option.title }}</el-radio>
            </el-radio-group>
          </div>
          <div class="checkbox-content" v-if="items.type===1"> <!--多项选择-->
            <el-checkbox-group class="checkbox_flex" v-model="checkbox">
              <el-checkbox :label="option.title" v-for="option in items.options" :key="option.id">{{ option.title }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="" v-if="items.type===2 || items.type===3" style="margin-top: 18px;"><!--单项填空、多项填空-->
            <div v-if="items.options.length">
              <div class="flex flex-col-center" v-for="option in items.options" :key="option.id" style="margin-top: 10px;">
                <input class="input-text" type="text" :placeholder="option.title"/>
                <!--<span class="unit-text" v-if="items.unit">{{items.unit}}</span>-->
              </div>
            </div>
            <div v-else>
              <input class="input-text" type="text"/>
            </div>
          </div>
          <div class="" v-if="items.type===4"><!--单选填空-->
            <el-radio-group v-model="radioInput[index]">
              <el-radio :label="option.order" v-for="(option) in items.options" :key="option.id">{{option.title}}</el-radio>
            </el-radio-group>
            <input v-if="radioInput[index] === 2" class="input-text" type="text" style="margin-top: 10px"/>
          </div>
          <div class="address-content flex flex-col-center" v-if="items.type===5"><!--详细地址-->
            <el-cascader v-model="addressValue[index]" :options="options" placeholder="请选择省市区"></el-cascader>
            <input class="address-input" type="text" placeholder="请填写详细地址"/>
          </div>
          <div class="date-content" v-if="items.type===6"><!--日期选择-->
            <el-date-picker v-model="dateText[index]" type="date" placeholder="年/月/日"></el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="close_btn">
      <a @click="closePage">关闭窗口</a>
    </div>
  </div>
</template>

<script>
import address from "../questionnaire/components/address";

export default {
  name: 'preview',
  data() {
    return {
      allDataList: {
        questionnaire: [
          {
            title: ''
          }
        ]
      },//所有数据
      radio: [],//单项选择
      checkbox: [],//多项选择
      radioInput: [],//单选填空
      options: [],//地区数据
      addressValue: [],//地区选择的数据
      dateText: [],//日期选择
    }
  },
  mounted() {
		let productId = this.$route.query.id;
		this.initData(productId);
		this.options = address;
  },
  methods: {
    initData(id) {
      this.$api.questionnaire_number_info({id: id}).then(res=>{
        this.allDataList = res.data;
      })
    },
    //标题颜色
    getTitleColor(type) {
      let style = {border: '1px solid #3984F5', color: '#3984F5'};
      switch (type) {
        case 0:
          style = {border: '1px solid #7065FF', color: '#7065FF'};
          break;
        case 1:
          style = {border: '1px solid #609DF7', color: '#609DF7'};
          break;
        case 2:
          style = {border: '1px solid #3984F5', color: '#3984F5'};
          break;
        case 3:
          style = {border: '1px solid #1C72F1', color: '#1C72F1'};
          break;
        case 4:
          style = {border: '1px solid #FB991A', color: '#FB991A'};
          break;
        case 5:
          style = {border: '1px solid #9C2DFA', color: '#9C2DFA'};
          break;
        case 6:
          style = {border: '1px solid #04CD83', color: '#04CD83'};
          break;
        default:
          break;
      }
      return style;
    },
    //关闭窗口
    closePage(){
      window.close();
    }
  }
}
</script>

<style scoped lang="scss">
.preview_box {width: 800px;margin: 18px auto;box-shadow: 0 0 15px rgba(153, 153, 153, .15);background-color: #fff;height: 100%;overflow: scroll;scrollbar-width: none;-ms-overflow-style: none;box-sizing: border-box;
  .close_btn {height: 118px;border-top: 1px solid #EDF1F4;display: flex;align-items: center;
    a {font-family: Microsoft YaHei;font-weight: 400;font-size: 14px;color: #6C7880;display: block;width: 120px;height: 40px;line-height: 40px;margin: 0 auto;background: #EEF1F3;text-align: center;}
  }
  .preview_title {text-align: center;height: 99px;line-height: 99px;font-weight: 400;font-size: 20px;color: #242D33;border-bottom: 1px solid rgba(153, 153, 153, .15);}
  .topic_box {padding: 0 30px 30px 30px;box-sizing: border-box;
    h2 {margin-top: 20px;font-weight: bold;font-size: 16px;color: #242D33;line-height: 36px;position: relative;
      &:before {content: '';position: absolute;width: 4px;height: 16px;background: #3984F5;left: -30px;top: 10px;}
    }
  }
  .topic-list-content {
    .name-content {margin-top: 30px;
      .type-text {width: 64px;height: 24px;background: #FFFFFF;font-size: 12px;text-align: center;line-height: 24px;}
      .topic-title {font-size: 14px;color: #242D33;margin-left: 11px;flex: 1;line-height: 26px;}
    }
    .input-text {width: 480px;height: 34px;background: #FFFFFF;border-radius: 4px;border: 1px solid #CEDAE0;line-height: 34px;padding: 0 12px;box-sizing: border-box;}
    .unit-text {font-size: 12px;color: #242D33;margin-left: 10px;line-height: 34px;}
    .el-radio-group {display: flex;flex-direction: column;align-items: flex-start;
      .el-radio {margin-top: 18px;}
    }
    .checkbox-content {
      .checkbox_flex {display: flex;flex-direction: column;
        .el-checkbox {margin-top: 16px;}
      }
    }
    .address-content {margin-top: 18px;
      .el-cascader {width: 280px;}
      .address-input {width: 360px;height: 40px;background: #FFFFFF;border-radius: 4px;border: 1px solid #CEDAE0;padding: 0 12px;margin-left: 10px;box-sizing: border-box;}
    }
    .date-content {margin-top: 18px;
      .el-input {width: 360px;}
    }
    .text-area-input {width: 100%;height: 120px;background: #FFFFFF;border-radius: 4px;border: 1px solid #CEDAE0;padding: 12px;box-sizing: border-box;margin-top: 18px;}
  }
}
</style>
